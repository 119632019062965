<template>
  <div>
    <img :src="pageImage" style="max-width:612px; max-heigh:792px" />
    <img class="destello previous" src="@/assets/arrow.png" />
    <span class="jobcode-date">-{{ currentDate }}</span>
    <span class="revision-date">{{ currentDate }}</span>
  </div>
</template>

<script>
import moment, { now } from "moment";
import Slide_End from "@/assets/slide_end.jpg";

export default {
  name: "EndPage",
  data() {
    return {};
  },
  props: {},
  computed: {
    pageImage() {
      return Slide_End;
    },
    currentDate() {
      return moment(Date.now()).format("M/YY");
    }
  },
  methods: {}
};
</script>

<style scoped>
.jobcode-date {
  position: absolute;
  top: 738.8px;
  left: 153px;
  font-size: 0.05em;
  font-family: "Oswald", sans-serif;
  font-weight: BOLD;
}

.revision-date {
  position: absolute;
  top: 754px;
  left: 238px;
  font-size: 0.05em;
  font-family: "Oswald", sans-serif;
  font-weight: BOLD;
}

.destello {
  position: absolute;
}
.next {
  width: 35px;
  transform: rotate(-45deg);
  right: 0;
  top: 0;
  animation: destello-efecto-01 3s infinite;
  -webkit-animation: destello-efecto-01 3s infinite;
}
.previous {
  width: 35px;
  transform: rotate(-135deg);
  left: 0;
  top: 0;
  animation: destello-efecto-02 3s infinite;
  -webkit-animation: destello-efecto-02 3s infinite;
}
/* #brochure div .previous{right: 550px; top: -50px; animation: destello-efecto-01 3s infinite; -webkit-animation: destello-efecto-01 3s infinite;} */
@keyframes destello-efecto-01 {
  0% {
    right: 10px;
    top: 10px;
  }
  50% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 10px;
    top: 10px;
  }
}
@-webkit-keyframes destello-efecto-01 {
  0% {
    right: 10px;
    top: 10px;
  }
  50% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 10px;
    top: 10px;
  }
}
@keyframes destello-efecto-02 {
  0% {
    left: 10px;
    top: 10px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 10px;
  }
}
@-webkit-keyframes destello-efecto-02 {
  0% {
    left: 10px;
    top: 10px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 10px;
    top: 10px;
  }
}
</style>
