<template>
  <div style="height:100%;">
    <loading :active.sync="isLoading" :is-full-page="true"></loading>
    <DesktopApp
      v-if="!isMobile"
      :clinicaltrials="clinicaltrials"
      v-show="!isLoading"
    ></DesktopApp>
    <MobileApp
      v-else
      :clinicaltrials="clinicaltrials"
      v-show="!isLoading"
    ></MobileApp>
  </div>
</template>

<script>
import $ from "jquery";
import "@/lib/turn.min.js";
import Loading from "vue-loading-overlay";

import DesktopApp from "@/components/DesktopApp.vue";
import MobileApp from "@/components/MobileApp.vue";

export default {
  name: "App",
  components: {
    DesktopApp,
    MobileApp,
    Loading
  },
  data() {
    return {
      isLoading: true,
      isMobile: true,
      userdata: {},
      clinicaltrials: []
    };
  },
  computed: {},
  props: {},
  watch: {},
  mounted() {
    var that = this;
    this.isLoading = true;
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    axios.get("https://api.ipify.org?format=json").then((response) => {
      that.userdata = response.data;
    });

    this.loadTrials().then((response) => {
      that.clinicaltrials = response;
      that.isLoading = false;
    });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1024;
    },
    loadTrials() {
      var that = this;
      return new Promise(function(resolve, reject) {
        var trials = [];
        var query1_expression =
          "('pembrolizumab' OR 'olaparib' OR 'lenvatinib' OR 'selumetinib') AND (AREA[CollaboratorName]'Merck Sharp & Dohme' OR AREA[LeadSponsorName]'Merck Sharp & Dohme') AND (AREA[LeadSponsorName]'AstraZeneca' OR AREA[LeadSponsorName]'Eisai' OR AREA[LeadSponsorName]'Merck Sharp & Dohme') AND (AREA[OverallStatus]COVERAGE[FullMatch]'Recruiting' OR AREA[OverallStatus]COVERAGE[FullMatch]'Not yet recruiting') AND AREA[StudyType]'Interventional' AND AREA[ResultsFirstPostDate]MISSING AND AREA[OrgClass]INDUSTRY";
        var query2_expression =
          "(MK-1454 OR MK-2118 OR MK-5890 OR MK-1026 OR ARQ-531 OR MK-2140 OR MK-4830 OR MK-4280 OR MK-7684 OR MK-1308 OR V940 OR V941 OR MK-6482 OR MK-6440 OR MK-1308A OR MK-4280A OR MK-7684A OR VLS-101) AND ((AREA[CollaboratorName]'Merck Sharp & Dohme' OR AREA[LeadSponsorName]'Merck Sharp & Dohme') OR (AREA[CollaboratorName]'peloton' OR AREA[LeadSponsorName]'peloton') OR (AREA[CollaboratorName]'arqule' OR AREA[LeadSponsorName]'arqule')) AND (AREA[OverallStatus]COVERAGE[FullMatch]'Recruiting' OR AREA[OverallStatus]COVERAGE[FullMatch]'Not yet recruiting') AND AREA[StudyType]'Interventional' AND AREA[ResultsFirstPostDate]MISSING AND AREA[OrgClass]INDUSTRY";
        axios
          .get(
            "https://clinicaltrials.gov/api/query/study_fields?expr=" +
              encodeURIComponent(query1_expression) +
              "&fields=NCTId%2CBriefTitle%2C+Phase%2C+Condition%2C+StudyFirstPostDate&min_rnk=1&max_rnk=1000&fmt=json"
          )
          .then((response) => {
            if (response.status == 200) {
              trials = trials.concat(
                that.processClinicalTrialInfo(
                  response.data.StudyFieldsResponse.StudyFields
                )
              );
            }
            axios
              .get(
                "https://clinicaltrials.gov/api/query/study_fields?expr=" +
                  encodeURIComponent(query2_expression) +
                  "&fields=NCTId%2CBriefTitle%2C+Phase%2C+Condition%2C+StudyFirstPostDate&min_rnk=1&max_rnk=1000&fmt=json"
              )
              .then((response) => {
                if (response.status == 200) {
                  trials = trials.concat(
                    that.processClinicalTrialInfo(
                      response.data.StudyFieldsResponse.StudyFields
                    )
                  );
                }

                // ORDER THE TRIALS
                var results = [];
                results = results.concat(
                  that.getTrialsby(trials, Conditions.NEW)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.SKIN)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.HEAD)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.GASTRO)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.LUNG)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.BREAST)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.GYNECOLOGICAL)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.GENITO)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.SOLID)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.HEMA)
                );
                results = results.concat(
                  that.getTrialsby(trials, Conditions.CNS)
                );

                //REMOVE DUPLICATE NCTS
                results = results.reduce((unique, item) => {
                  var encontrado = -1;
                  encontrado = unique.findIndex((t) => {
                    var isthesameNCT = t.nct == item.nct;
                    var isthesameCondition =
                      t.condition[0] == item.condition[0];
                    // var isNew = t.condition.indexOf("new")!= -1;
                    // var condicion = isthesameNCT && !isNew;
                    var condicion = isthesameNCT && isthesameCondition;
                    return condicion;
                  });
                  return encontrado != -1 ? unique : [...unique, item];
                }, []);

                resolve(results);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    processClinicalTrialInfo(items) {
      var that = this;
      var results = items.map(function(element) {
        var result = null;
        if (that.processCondition(element.Condition).length > 0) {
          result = {
            nct: element.NCTId[0],
            title: element.BriefTitle[0],
            phase: element.Phase[0].replace("Phase ", ""),
            condition: that.processCondition(element.Condition),
            studyFirstPostDate: new Date(element.StudyFirstPostDate[0])
          };
        }
        return result;
      });
      results = results.filter((item, index) => {
        return item != null;
      });
      return results;
    },
    processCondition(clinicaltrialsCondition) {
      var results = [];
      clinicaltrialsCondition.forEach((condition) => {
        var processcond = condition.toLowerCase().trim();
        if (
          skin.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.SKIN) == -1
        )
          results.push(Conditions.SKIN);
        if (
          head.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.HEAD) == -1
        )
          results.push(Conditions.HEAD);
        if (
          gastro.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.GASTRO) == -1
        )
          results.push(Conditions.GASTRO);
        if (
          lung.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.LUNG) == -1
        )
          results.push(Conditions.LUNG);
        if (
          breast.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.BREAST) == -1
        )
          results.push(Conditions.BREAST);
        if (
          gynecological.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.GYNECOLOGICAL) == -1
        )
          results.push(Conditions.GYNECOLOGICAL);
        if (
          genito.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.GENITO) == -1
        )
          results.push(Conditions.GENITO);
        if (
          solid.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.SOLID) == -1
        )
          results.push(Conditions.SOLID);
        if (
          hema.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.HEMA) == -1
        )
          results.push(Conditions.HEMA);
        if (
          cns.indexOf(processcond) != -1 &&
          results.indexOf(Conditions.CNS) == -1
        )
          results.push(Conditions.CNS);
      });
      return results;
    },
    getTrialsby(initialData, value) {
      var results = [];
      var that = this;
      var currentDate = new Date();

      results = initialData.filter((currentValue, index, array) => {
        var result = false;

        if (value == "new") {
          var diffInMs = currentDate - currentValue.studyFirstPostDate;
          result = diffInMs / (1000 * 60 * 60 * 24) < 30;
        } else result = currentValue.condition.indexOf(value) != -1;

        return result;
      }, this);

      results = results.map(function callback(currentValue, index, array) {
        var newvalue = Object.assign({}, currentValue);
        newvalue.condition = [value];
        return newvalue;
      });
      return results;
    }
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  }
};

const Conditions = {
  NEW: "new",
  SKIN: "skin",
  HEAD: "head",
  GASTRO: "gastro",
  LUNG: "lung",
  BREAST: "breast",
  GYNECOLOGICAL: "gynecological",
  GENITO: "genito",
  SOLID: "solid",
  HEMA: "hema",
  CNS: "cns"
};

var breast = ["breast cancer", "triple negative breast neoplasms"];
var cns = [
  "gbm",
  "glioblastoma",
  "glioblastoma multiforme",
  "glioblastoma, adult"
];
var gastro = [
  "biliary tract carcinoma",
  "carcinoma, hepatocellular",
  "colorectal cancer",
  "colorectal neoplasms",
  "esophageal adenocarcinoma (eac)",
  "esophageal squamous cell carcinoma (escc)",
  "gastric cancer",
  "gastric neoplasms",
  "gastroesophageal junction adenocarcinoma",
  "gastroesophageal junction cancer",
  "hepatocellular carcinoma",
  "metastatic colorectal cancer",
  "pancreatic neoplasms",
  "siewert type i adenocarcinoma of the esophagogastric junction (egj)",
  "stomach neoplasms"
];
var genito = [
  "carcinoma, renal cell",
  "ccrcc",
  "clear cell renal cell carcinoma",
  "kidney",
  "kidney cancer",
  "metastatic castration-resistant prostate cancer",
  "metastatic hormone-sensitive prostate cancer",
  "prostatic neoplasms",
  "rcc",
  "rcc, clear cell adenocarcinoma",
  "renal cancer",
  "renal cell cancer metastatic",
  "renal cell cancer, recurrent",
  "renal cell carcinoma",
  "renal cell carcinoma recurrent",
  "renal cell carcinoma, clear cell adenocarcinoma",
  "renal cell carcinoma, metastatic",
  "bladder cancer",
  "high-risk non-muscle invasive bladder cancer",
  "urinary bladder cancer, muscle-invasive",
  "urinary bladder neoplasms",
  "urothelial carcinoma"
];
var gynecological = [
  "endometrial neoplasms",
  "fallopian tube cancer",
  "ovarian cancer",
  "peritoneal neoplasms",
  "uterine cervical neoplasms"
];
var head = [
  "carcinoma, squamous cell",
  "head and neck neoplasms",
  "head and neck squamous cell carcinoma",
  "head and neck squamous cell carcinoma (hnscc)"
];
var hema = [
  "chronic lymphocytic leukemia",
  "classical hodgkin lymphoma",
  "diffuse large b cell lymphoma",
  "follicular lymphoma",
  "hodgkin disease",
  "hodgkin lymphoma",
  "lymphoma",
  "lymphoma, b-cell",
  "lymphoma, non-hodgkin",
  "mantle cell lymphoma",
  "marginal zone lymphoma",
  "richter's transformation",
  "small lymphocytic lymphoma",
  "waldenstrom macroglobulinemia"
];
var lung = [
  "advanced non-small cell lung cancer",
  "small cell lung carcinoma",
  "carcinoma, non-small-cell lung",
  "carcinoma, non-squamous non-small-cell lung",
  "carcinoma, squamous cell, non-small-cell lung",
  "mesothelioma",
  "metastatic non-small cell lung cancer",
  "non-small cell lung cancer",
  "nonsquamous non-small cell lung cancer"
];
var skin = [
  "advanced/metastatic melanoma",
  "malignant melanoma",
  "melanoma",
  "merkel cell carcinoma"
];
var solid = [
  "advanced solid neoplasms",
  "advanced solid tumors",
  "advanced/metastatic solid tumors",
  "microsatellite-instability-high solid tumor",
  "solid carcinoma",
  "solid tumor",
  "solid tumor, adult",
  "solid tumors",
  "osteosarcoma"
];
</script>

<style>
@import "~vue-loading-overlay/dist/vue-loading.css";
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
</style>
